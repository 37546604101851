import React from 'react';
import { COMPANY, MAGNACARE, JIB } from 'config/routes'

const FooterLink = ({ href, text }) => {
  return (
    <a
      href={href}
      className="text-gray700 hover:underline text-sm font-semibold text-nowrap"
      target="_blank"
      rel="noopener noreferrer"
    >
      {text}
    </a>
  );
};

const renderMagnacareDisclaimer = () => {
  const isJib = location.pathname?.startsWith(`/${COMPANY}/${MAGNACARE}/${JIB}`) ?? false;
  if (!isJib) return null

  return (
    <div className="flex text-left mt-6 sm:mt-0 text-2xs text-gray-500">
      Brighton Health Plan Solutions, LLC, dba MagnaCare Administrative Services (“MagnaCare”) provides administrative services on behalf of the Pension Hospitalization and Benefit Plan of the Electrical Industry. Ciba Health Inc. is a vendor engaged to provide support for chronic health conditions to eligible participants of Pension Hospitalization and Benefit Plan of the Electrical Industry. Ciba Health Inc., and not MagnaCare nor the Joint Industry Board of the Electrical Industry, manages and hosts this page.
    </div >
  )
}

const EnrolmentFooter = () => {
  const currentYear = new Date().getFullYear();

  return (

    <div className='flex flex-col w-full px-[24px] py-4 gap-[6px]'>
      {renderMagnacareDisclaimer()}
      <div className="flex w-full flex-col md:flex-row py-[40px] justify-between gap-3 text-left">
        <span className="text-gray500 text-2xs">
          © {currentYear} Ciba Health All Rights Reserved.
        </span>
        <div className="flex gap-[8px] mr-32">
          <FooterLink href="https://cibahealth.com/terms-of-use/" text="Terms of Use" />
          <FooterLink href="https://cibahealth.com/privacy-policy" text="Privacy Policy" />
        </div>
      </div>
    </div>
  );
};

export default EnrolmentFooter;
